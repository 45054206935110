import React, { useEffect, useState, useCallback, SyntheticEvent, useRef } from "react";
import {
  ADVANCED_SEARCH_CLEAR_SELECTS,
  ADVANCED_SEARCH_UPDATE_PAYPERIOD,
  ADVANCED_SEARCH_UPDATE_RECRUITER,
  ADVANCED_SEARCH_UPDATE_TRAVELER,
  TITLE_CHANGED,
} from "redux/actions/actionTypes";
import { useDispatch } from "react-redux";
import MaterialTable, { QueryResult } from "material-table";
import { Button, FormControlLabel, Fade, Switch, Grid } from "@mui/material";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import TimeCardAdjusmentsIcon from "components/shared/components/TimeCardAdjusmentsIcon";
import TimecardFileIcon from "components/shared/components/TimecardFileIcon";
import TimecardIsLateIcon from "components/shared/components/TimecardIsLateIcon";
import FileFordwardedIcon from "components/shared/components/FileFordwardedIcon";
import RefreshIcon from "@mui/icons-material/Refresh";
import PayPeriodRangeSelector from "./PayPeriodRangeSelector";
import SearchIcon from "@mui/icons-material/Search";
import GetAppIcon from "@mui/icons-material/GetApp";
import moment from "moment";
import { Link } from "react-router-dom";
import TravelerSearch from "./TravelerSearch";
import RecruiterSearch from "./RecruiterSearch";
import FacilitySearch from "./FacilitySearch";
import AlertDialog from "components/shared/components/AlertDialog";
import DetailsSearch from "components/history/DetailsSearch";
import StatusSearch from "./StatusSearch";
import useAppSettings from "hooks/useAppSettings";
import SignatureIcon from "components/shared/components/SignatureIcon";
import { TimesheetSubStatusMap } from "commons/Enums";
import FacilityReportAttachedIcon from "components/shared/components/FacilityReportAttachedIcon";
import { Box } from "@mui/system";
import { useAppSelector } from "hooks";
import {
  AdvancedSearchQuery,
  downloadCsv,
  getTimesheetStates,
  makeSearch,
} from "api/advancedSearchApi";
import { downloadBlob } from "commons/utils";
import { RecruiterState } from "types/recruiter";
import { TravelerState } from "types/traveler";
import JobAssignmentApi from "api/JobAssignmentApi";
import MaterialTableLoader from "components/shared/components/MaterialTableLoader";

const AdvancedSearchHistory = () => {
  const materialTableRef = useRef(MaterialTable);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [shouldResetPage, setShouldResetPage] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [states, setStates] = useState([]);
  const [maxDateFilters, setMaxDateFilters] = useState<Date>(new Date());

  const dispatch = useDispatch();
  const headerState = useAppSelector((state) => state.header);
  const currentFilters = useAppSelector((state) => state.advancedSearch);
  const { advancedSearchMaxRowsExport } = useAppSettings();

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const distanceTableFromTop =
    (materialTableRef as any)?.current?.tableContainerDiv?.current?.getBoundingClientRect()?.top ??
    0;
  const safeDistanceTableFromTop = distanceTableFromTop + 80; // 80 for table header
  const tableBodyMaxHeight = `calc(100vh - ${safeDistanceTableFromTop}px)`;

  const handleToggleChange = useCallback(() => {
    // if it's shown then it's going be hidden so we clear the filters inside
    if (showMoreFilters) {
      dispatch({
        type: ADVANCED_SEARCH_CLEAR_SELECTS,
      });
    }
    setShowMoreFilters((prev) => !prev);
  }, [showMoreFilters]);

  const handleRefreshHistoryState = () => {
    window.location.reload();
  };

  const getTimesheetStatesApi = async () => {
    getTimesheetStates()
      .then((response) => {
        setStates(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Advanced Search" });

    getTimesheetStatesApi();

    if (
      (currentFilters.facilities?.length ?? 0) > 0 ||
      currentFilters.states.length > 0 ||
      currentFilters.details.length > 0
    ) {
      setShowMoreFilters(true);
    }

    JobAssignmentApi.getMaxDate().then((response) => {
      setMaxDateFilters(new Date(response));
    });
  }, []);

  useEffect(() => {
    if (!currentFilters.startDate || !currentFilters.endDate) {
      dispatch({
        type: ADVANCED_SEARCH_UPDATE_PAYPERIOD,
        startDate: headerState.startDate,
        endDate: headerState.endDate,
      });
    }
  }, [headerState]);

  useEffect(() => {
    if (isFirstRender && currentFilters.startDate && currentFilters.endDate) {
      handleSearch();
    }
  }, [currentFilters.startDate, currentFilters.endDate]);

  const handleSearch = async () => {
    setAnchorEl(null);
    if (!isFirstRender) {
      setShouldResetPage(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (materialTableRef.current as any).onQueryChange();
    } else {
      setShouldResetPage(false);
      setIsFirstRender(false);
    }
  };

  const loadData = useCallback(
    /**
     * @param {import("material-table").Query} query
     */
    (query) => {
      const makeQuery: AdvancedSearchQuery = {
        ...query,
        filters: currentFilters,
      };
      return new Promise((resolve, reject) => {
        if (shouldResetPage) {
          query.page = 0;
        }
        setPageSize(makeQuery.pageSize);
        // here will pass all the filters to makeSearch so that it sends to the API
        makeSearch(makeQuery)
          .then((response) => {
            resolve({
              data: response.rows,
              page: query.page,
              totalCount: response.totalRows,
            });
          })
          .catch((error) => {
            console.error({ error });
            reject(error);
          })
          .finally(() => {
            setShouldResetPage(false);
          });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as Promise<QueryResult<any>>;
    },
    [currentFilters, shouldResetPage]
  );

  const isCurrentPayPeriod = (timesheetStartDate: string) => {
    return new Date(timesheetStartDate).getTime() === new Date(headerState.startDate).getTime();
  };

  const isFuturePayPeriod = (timesheetStartDate: string) => {
    return new Date(timesheetStartDate).getTime() > new Date(headerState.startDate).getTime();
  };

  const formatDate = (date: string) => {
    return moment(new Date(date)).format("MM/DD/YYYY");
  };

  const getColumns = () => {
    return [
      {
        title: "Pay Period",
        field: "payPeriod",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (rowData: any) => {
          return (
            <>
              {formatDate(rowData.timeSheetStartDate)}
              {" - "}
              <br />
              {formatDate(rowData.timeSheetEndDate)}
            </>
          );
        },
      },
      {
        title: "Traveler",
        field: "traveler",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (rowData: any) =>
          // eslint-disable-next-line no-nested-ternary
          isCurrentPayPeriod(rowData.timeSheetStartDate) ? (
            <Link
              to={{
                pathname: `/home/${rowData.recruiterId}/${rowData.timesheetId}/${formatDate(
                  headerState.startDate
                )}/${formatDate(headerState.endDate)}`,
              }}>
              {rowData.traveler}
            </Link>
          ) : isFuturePayPeriod(rowData.timeSheetStartDate) ? (
            <span>{rowData.traveler}</span>
          ) : (
            <Link to={"/traveler-history/" + rowData.jobAssigmentId} target="_blank">
              {rowData.traveler}
            </Link>
          ),
      },
      { title: "Recruiter", field: "recruiter" },
      { title: "Sales Representative", field: "salesRep" },
      { title: "Facility", field: "facility" },
      {
        title: "Status",
        field: "status",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (rowData: any) => {
          if (rowData.status === "No Timecard") {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return (TimesheetSubStatusMap as any)[rowData.subStatus];
          }

          return rowData.status;
        },
      },
      { title: "Dealsheet ID", field: "dealsheetLaborEdgeId" },
      {
        title: "Assignment Period",
        field: "jobAssigmentPeriod",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (rowData: any) => {
          return (
            <>
              {formatDate(rowData.jobAssigmentStartDate)}
              {" - "}
              <br />
              {formatDate(rowData.jobAssigmentEndDate)}
            </>
          );
        },
      },
      {
        title: "Details",
        field: "details",
        sorting: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (rowData: any) => (
          <div className="history-table-icons-wrap">
            <FacilityReportAttachedIcon
              timesheetId={rowData.timesheetId}
              filled={!!rowData.facilityReportUploadId}
            />

            <SignatureIcon
              signatureStatus={rowData.managerSignatureStatus}
              timesheetId={rowData.timesheetId}
              hassEditRequest={rowData.hasEditRequest}
              refreshData={loadData}
              facilityId={rowData.facilityId}
            />

            <TimecardFileIcon
              timecardUploadId={rowData.timecardUploadId}
              uploadSource={rowData.timecardUploadSource}
            />

            <FileFordwardedIcon
              forwardingStatus={rowData.forwardingStatus}
              icon="forward_to_inbox"
              timesheetId={rowData.timesheetId}
            />

            <TimecardIsLateIcon isLate={rowData.isLate} />

            <CanRenderChildren featureFlagName="isAdjustmentsOn">
              <TimeCardAdjusmentsIcon
                isAdjustment={rowData.isAdjustment}
                hasAdjustment={rowData.hasAdjustment}
                dtHoursAdjusted={rowData.dtHoursAdjusted}
              />
            </CanRenderChildren>
          </div>
        ),
      },
    ];
  };

  const downloadCSV = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((materialTableRef as any).current.state.query.totalCount > advancedSearchMaxRowsExport) {
      setIsAlertOpen(true);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query = (materialTableRef as any).current.state.query;
    query.filters = currentFilters;
    downloadCsv(query)
      .then((blob) => {
        downloadBlob(new Blob([blob]), `report.csv`);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const closeAlert = () => {
    setIsAlertOpen(false);
  };

  const handleOnFilterDateChange = (startDate?: string, endDate?: string) => {
    dispatch({
      type: ADVANCED_SEARCH_UPDATE_PAYPERIOD,
      startDate,
      endDate,
    });
  };

  const handleRecruiterChange = (_: SyntheticEvent<Element, Event>, newValue: RecruiterState) => {
    dispatch({
      type: ADVANCED_SEARCH_UPDATE_RECRUITER,
      recruiter: newValue,
    });
  };

  const handleTravelerChange = (_: SyntheticEvent<Element, Event>, newValue: TravelerState) => {
    dispatch({
      type: ADVANCED_SEARCH_UPDATE_TRAVELER,
      traveler: newValue,
    });
  };

  return (
    <Box mx={2} mt={3} mb={2}>
      <Grid container spacing={2}>
        <Grid item>
          {maxDateFilters && (
            <PayPeriodRangeSelector
              title="Period Range"
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              onHandleChange={handleOnFilterDateChange}
              startDate={currentFilters.startDate}
              endDate={currentFilters.endDate}
              showPresetsFutureDates={true}
              maxDate={maxDateFilters}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TravelerSearch
            currentTraveler={currentFilters.traveler}
            handleChange={handleTravelerChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RecruiterSearch
            currentRecruiter={currentFilters.recruiter}
            handleChange={handleRecruiterChange}
          />
        </Grid>
        <Grid item xs={12} md={5} lg={3} style={{ order: showMoreFilters ? 2 : 1 }}>
          <Button
            onClick={handleSearch}
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}>
            Search
          </Button>
          <FormControlLabel
            control={
              <Switch checked={showMoreFilters} color="primary" onChange={handleToggleChange} />
            }
            label="More Options"
            style={{ marginLeft: 5 }}
          />
        </Grid>

        <Fade in={showMoreFilters}>
          <Grid item xs={12} sm={6} md={3} style={{ display: showMoreFilters ? "block" : "none" }}>
            <FacilitySearch />
          </Grid>
        </Fade>
        {states.length > 0 && (
          <Fade in={showMoreFilters}>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              style={{ display: showMoreFilters ? "block" : "none" }}>
              <StatusSearch states={states} />
            </Grid>
          </Fade>
        )}

        <Fade in={showMoreFilters}>
          <Grid item xs={12} sm={6} md={2} style={{ display: showMoreFilters ? "block" : "none" }}>
            <DetailsSearch />
          </Grid>
        </Fade>
      </Grid>

      {!isFirstRender && (
        <div style={{ textAlign: "right" }}>
          <Button onClick={handleRefreshHistoryState}>
            <RefreshIcon color="primary" />
            Reset filters
          </Button>
          <Button onClick={downloadCSV} startIcon={<GetAppIcon color="primary" />}>
            Export
          </Button>
        </div>
      )}
      <Box style={{ marginTop: 10 }}>
        {!isFirstRender && (
          <MaterialTable
            tableRef={materialTableRef}
            style={{ overflow: "auto", fontSize: 14 }}
            data={loadData}
            columns={getColumns()}
            onQueryChange={loadData}
            components={{
              OverlayLoading: MaterialTableLoader,
            }}
            options={{
              sorting: true,
              filtering: false,
              search: false,
              showTitle: false,
              pageSize: pageSize,
              maxBodyHeight: tableBodyMaxHeight,
              pageSizeOptions: [25, 50, 100],
            }}
          />
        )}
      </Box>
      <AlertDialog
        isOpen={isAlertOpen}
        onClose={closeAlert}
        dialogTitle="Attention"
        dialogText="Your selection is too large to download"
        confirmText="Close"
      />
    </Box>
  );
};

export default AdvancedSearchHistory;
