import React, { useState, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import _debounce from "lodash/debounce";
import { TextField, Button } from "@mui/material";
import JobAssignmentApi from "api/JobAssignmentApi";
import AssignmentsListSelector from "components/shared/components/AssignmentsListSelector";

type ExpenseJobAssignmentSelectorProps = {
  open: boolean;
  recruiterId: string;
  onClose: () => void;
  onSelect: (assignment: any) => void;
};

const ExpenseJobAssignmentSelector = (props: ExpenseJobAssignmentSelectorProps) => {
  const [selectedAssignment, setSelectedAssignment] = useState<object|null>({});
  const [assignmentsFound, setAssignmentsFound] = useState<never|null>(null);
  const [filter, setFilter] = useState<string|null>(null);
  const snackbar = useSnackbar();

  const { recruiterId } = props;

  const onAssignmentSelected = (assignment: AssignmentSelected) => {
    setSelectedAssignment(assignment);
  };

  const onSelect = () => {
    props.onSelect(selectedAssignment);
    setSelectedAssignment(null);
    setAssignmentsFound(null);
  };

  const onClose = () => {
    setSelectedAssignment(null);
    setAssignmentsFound(null);
    props.onClose();
  };

  const debouncedFetch = (recruiterId: string, travelerName: string) => {
    if (!travelerName || travelerName.length < 3) {
      setAssignmentsFound(null);
      return;
    }

    JobAssignmentApi.getJobAssignmentsByRecruiter(recruiterId, travelerName)
      .then((results) => {
        setAssignmentsFound(results);

        setFilter(travelerName);

        if (results.length === 0) {
          return;
        }

        setSelectedAssignment(null);
      })
      .catch((error) => {
        console.log(error);
        snackbar.enqueueSnackbar("An error occurred searching for job assignments.");
        setAssignmentsFound(null);
      });
  };

  const debounceFn = useCallback(_debounce(debouncedFetch, 400), []);

  const handleTravelerChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    debounceFn(recruiterId, event.target.value);
  };

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={"new-timecard-dialog"}>
      <DialogTitle id="form-dialog-title">Select Job Assignment</DialogTitle>
      <DialogContent>
        <DialogContentText>Select a traveler and an assignment.</DialogContentText>
        <TextField
          sx={{ marginTop: 2 }}
          fullWidth
          id="traveler"
          name="traveler"
          label="Traveler"
          type="traveler"
          placeholder="Search a traveler by name or by job ID"
          onChange={(e) => handleTravelerChange(e)}
        />
        <div className="new-timecard-assignments-list-container">
          {assignmentsFound && (
            <AssignmentsListSelector
              assignments={assignmentsFound}
              selectedAssignment={selectedAssignment}
              onAssignmentSelected={onAssignmentSelected}
              filteredName={filter}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button disabled={!selectedAssignment} onClick={onSelect} color="primary">
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExpenseJobAssignmentSelector;
