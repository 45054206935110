import { Box, Button, Chip, Tooltip, Typography } from "@mui/material";
import BPPTable, { Column } from "components/shared/components/bppTable/BPPTable";
import React, { useState } from "react";
import { UserSearch } from "./UserSearch";
import { useSnackbar } from "notistack";
import { deleteCandidateEmail, getUsersEmails, restoreCandidateEmail } from "api/UsersApi";
import { UsersEmailsTableItem, UsersEmailsTableItemEmails } from "./UsersEmailsTableItem";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import moment from "moment";
import { DTEMAIL_EMAIL_CHANGED, DTEMAILS_NAME_CHANGED } from "redux/actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import AddUserEmailDialog from "./AddUserEmailDialog";
import ConfirmDialog from "components/shared/components/ConfirmDialog";
import { usePermissions } from "hooks";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";

type UsersDataIds = "name" | "emails" | "actions";

const UsersEmails = () => {
  const [data, setData] = useState<UsersEmailsTableItem[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dtUsersEmails = useSelector((state: RootState) => state.dtUsersEmails);
  const dispatch = useDispatch();
  const [isAddEmailDialogOpen, setIsAddEmailDialogOpen] = useState(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState("");
  const [selectedCandidateEmail, setSelectedCandidateEmail] = useState("");
  const [isDeleteCandidateEmailDialogOpen, setIsDeleteCandidateEmailDialogOpen] = useState(false);
  const [isRestoreCandidateEmailDialogOpen, setIsRestoreCandidateEmailDialogOpen] = useState(false);
  const { canContributeDigitalTimecardEmails } = usePermissions();

  const handleCancelName = () => {
    dispatch({ type: DTEMAILS_NAME_CHANGED, value: "" });
  };

  const handleCancelEmail = () => {
    dispatch({ type: DTEMAIL_EMAIL_CHANGED, value: "" });
  };

  const handleChangedName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: DTEMAILS_NAME_CHANGED, value: e.target.value });
  };

  const handleChangedEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: DTEMAIL_EMAIL_CHANGED, value: e.target.value });
  };

  const handleClear = () => {
    handleCancelName();
    handleCancelEmail();
  };

  const handleAddEmail = (candidateId: string) => {
    setSelectedCandidateId(candidateId);
    setIsAddEmailDialogOpen(true);
  };

  const columns: Column<UsersDataIds, UsersEmailsTableItem>[] = [
    {
      id: "name",
      label: "Name",
      format(value) {
        return (
          <Typography fontSize={13} sx={{ textTransform: "capitalize" }}>
            {value as string}
          </Typography>
        );
      },
    },
    {
      id: "emails",
      label: "Emails",
      format(value, data) {
        return (
          <>
            {(value as UsersEmailsTableItemEmails[]).map((x) => (
              <Typography key={x.email} mb={1}>
                <Chip
                  label={
                    <Tooltip
                      arrow
                      title={
                        x.active
                          ? `Last modified: ${moment(new Date(x.lastModifiedDate)).format(
                              "MM/DD/YYYY HH:mm"
                            )}`
                          : `Deleted at: ${moment(x.dateDeleted).format("MM/DD/YYYY HH:mm")}`
                      }>
                      <Typography>{x.email}</Typography>
                    </Tooltip>
                  }
                  color={x.active ? "primary" : undefined}
                  icon={
                    x.inB2C ? (
                      <Tooltip arrow title="In B2C">
                        <HowToRegIcon />
                      </Tooltip>
                    ) : undefined
                  }
                  onDelete={
                    canContributeDigitalTimecardEmails ? () => handleDelete(data, x) : undefined
                  }
                  deleteIcon={
                    // eslint-disable-next-line no-nested-ternary
                    canContributeDigitalTimecardEmails ? (
                      x.active ? (
                        <Tooltip arrow title="Delete">
                          <DeleteIcon color="error" />
                        </Tooltip>
                      ) : (
                        <Tooltip arrow title="Enable">
                          <CheckCircleIcon color="primary" />
                        </Tooltip>
                      )
                    ) : undefined
                  }></Chip>
              </Typography>
            ))}
          </>
        );
      },
    },
    {
      id: "actions",
      label: "Actions",
      disableFilter: true,
      format: (value, data) => (
        <CanRenderChildren permissionName="canContributeDigitalTimecardEmails">
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Button onClick={() => handleAddEmail(data.candidateId)}>
              <AddIcon />
              New Email
            </Button>
          </Box>
        </CanRenderChildren>
      ),
    },
  ];

  const handleSearch = async () => {
    try {
      if (!dtUsersEmails?.name && !dtUsersEmails?.email) {
        enqueueSnackbar("Please enter a name or email", { variant: "warning" });
        return;
      }

      setLoadingData(true);
      const users = await getUsersEmails(dtUsersEmails?.name, dtUsersEmails?.email);
      setData(users.map((x) => ({ ...x, id: x.candidateId })));
    } catch (error) {
      enqueueSnackbar("Error fetching users", { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  };

  const handleDelete = (candidate: UsersEmailsTableItem, emailItem: UsersEmailsTableItemEmails) => {
    setSelectedCandidateId(candidate.candidateId);
    setSelectedCandidateEmail(emailItem.email);

    if (emailItem.active) {
      setIsDeleteCandidateEmailDialogOpen(true);
    } else {
      setIsRestoreCandidateEmailDialogOpen(true);
    }
  };

  const textFieldsProps = [
    {
      label: "Traveler Name",
      name: "travelerName",
      handleChangeValue: handleChangedName,
      handleClearValue: handleCancelName,
      value: dtUsersEmails?.name ?? "",
    },
    {
      label: "Traveler Email",
      name: "travelerEmail",
      handleChangeValue: handleChangedEmail,
      handleClearValue: handleCancelEmail,
      value: dtUsersEmails?.email ?? "",
    },
  ];

  const onConfirmRestore = async () => {
    await restoreCandidateEmail(selectedCandidateId, selectedCandidateEmail);
    setIsRestoreCandidateEmailDialogOpen(false);
    await handleSearch();

    setSelectedCandidateId("");
    setSelectedCandidateEmail("");
  };

  const onConfirmDelete = async () => {
    await deleteCandidateEmail(selectedCandidateId, selectedCandidateEmail);
    setIsDeleteCandidateEmailDialogOpen(false);
    await handleSearch();

    setSelectedCandidateId("");
    setSelectedCandidateEmail("");
  };

  return (
    <>
      <BPPTable<UsersEmailsTableItem, UsersDataIds>
        columns={columns}
        data={data}
        loading={loadingData}
        handleClear={handleClear}
        defaultOrderingBy="name"
        customSearch={
          <UserSearch
            handleSearch={handleSearch}
            disabled={loadingData}
            textFieldsProps={textFieldsProps}
          />
        }
      />
      <AddUserEmailDialog
        isOpen={isAddEmailDialogOpen}
        onClose={() => setIsAddEmailDialogOpen(false)}
        onSearch={handleSearch}
        selectedCandidateId={selectedCandidateId}
      />

      <ConfirmDialog
        isOpen={isRestoreCandidateEmailDialogOpen}
        dialogTitle="Restore"
        content={`Are you sure you want to restore this email?`}
        cancelText="Close"
        confirmText="Restore"
        onConfirm={onConfirmRestore}
        onCancel={() => setIsRestoreCandidateEmailDialogOpen(false)}
      />

      <ConfirmDialog
        isOpen={isDeleteCandidateEmailDialogOpen}
        dialogTitle="Delete"
        content={`Are you sure you want to delete this email?`}
        cancelText="Close"
        confirmText="Delete"
        onConfirm={onConfirmDelete}
        onCancel={() => setIsDeleteCandidateEmailDialogOpen(false)}
      />
    </>
  );
};

export default UsersEmails;
