import { Box, Button, CircularProgress, Divider, Typography } from "@mui/material";
import { usePermissions, useReferalsDocument } from "hooks";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { TITLE_CHANGED } from "redux/actions/actionTypes";
import { ReferralTableItem } from "types/referrals";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReferralStatus, ReferralsPaymentType, ReferrerPaperworkStatus } from "commons/Enums";
import ConfirmDialog from "components/shared/components/ConfirmDialog";
import { getReferralById, markReferralAsToBePaid, syncDeal } from "api/ReferralsApi";
import { useSnackbar } from "notistack";
import moment from "moment";
import ReferralsDocumentsDialog from "./components/ReferralsDocumentsDialog";

const Field = ({ label, text }: { label: string; text: string }) => {
  return (
    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography style={{ fontWeight: 600, color: "#414141", fontSize: "0.9rem" }}>
        {label}
      </Typography>
      <Typography style={{ fontWeight: 500, color: "#7D7D7D", fontSize: "0.9rem" }}>
        {text}
      </Typography>
    </Box>
  );
};

const getPaperworkStatusAction = (
  referral: ReferralTableItem,
  action: (dealId: string | undefined, referrerEmail: string) => void
): JSX.Element => {
  switch (referral.paperworkStatus) {
    case ReferrerPaperworkStatus.NotSent:
      return (
        <Button
          color="primary"
          style={{ textTransform: "capitalize", fontSize: 16 }}
          onClick={() => action(referral.dealId, referral.referrerEmail)}>
          Send Documents
        </Button>
      );
    case ReferrerPaperworkStatus.Signed:
      return (
        <>
          <Button
            color="primary"
            style={{ textTransform: "capitalize", fontSize: 16 }}
            href={referral.paperworkDownloadUrl!}
            download
            target="_blank">
            Download
          </Button>
          <Button
            onClick={() => action(referral.dealId, referral.referrerEmail)}
            color="primary"
            style={{ textTransform: "capitalize", fontSize: 16 }}
            className={"referrals-referrer-send-button"}>
            Resend Documents
          </Button>
        </>
      );
    case ReferrerPaperworkStatus.PendingSignature:
      return (
        <Button color="primary" style={{ textTransform: "capitalize", fontSize: 16 }} disabled>
          Documents sent
        </Button>
      );
    default:
      return <></>;
  }
};

type ReferralDetailProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  match?: any;
};

const ReferralDetail = ({ match }: ReferralDetailProps) => {
  const { dealId } = match.params;
  const [referral, setReferral] = useState<ReferralTableItem>();

  const [isMarkOpen, setMarkOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    isDocumentsDialogOpen,
    toggleDocumentsDialogOff,
    toggleDocumentsDialogOn,
    handleSendDocumentsToReferrer,
    isEmailReminderDialogOpen,
    handleEmailReminderToReferrer,
    toggleEmailReminderDialogOff,
    toggleEmailReminderDialogOn,
  } = useReferalsDocument(dealId);

  const history = useHistory();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const {
    canRequestReferrerPaperwork,
    canSeeReferrerPaperwork,
    canDownloadReferrerPaperwork,
    canSendEmailReminderReferrerPaperwork,
  } = usePermissions();

  async function getReferralsFromApi(): Promise<void> {
    setLoading(true);
    const referralFound = await getReferralById(dealId);

    if (!referralFound) {
      history.push("/referrals");
      return;
    }

    setReferral(referralFound);
    setLoading(false);
  }

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Referral Details" });
    getReferralsFromApi();
  }, []);

  const showPaperworkStatus =
    (canRequestReferrerPaperwork ||
      canSeeReferrerPaperwork ||
      canDownloadReferrerPaperwork ||
      canSendEmailReminderReferrerPaperwork) &&
    referral?.paperworkStatus !== ReferrerPaperworkStatus.PendingSignature;

  const toggleMarkDialog = () => {
    setMarkOpen((prev) => !prev);
  };

  const onConfirmSend = async (email: string) => {
    try {
      await handleSendDocumentsToReferrer(email);
      await getReferralsFromApi();
    } catch (error) {
      console.error(error);
    }
  };

  const handleMarkAsPaid = async () => {
    try {
      const response = await markReferralAsToBePaid(referral!.dealId);

      if (response.length > 0) {
        snackbar.enqueueSnackbar(response, { variant: "error" });
        return;
      }

      getReferralsFromApi();
      snackbar.enqueueSnackbar("Marked as 'To Be Paid'", { variant: "success" });
    } catch (error) {
      snackbar.enqueueSnackbar("Error marking as 'To Be Paid'", { variant: "error" });
    }
  };

  const handleSendReminder = async (email: string) => {
    try {
      await handleEmailReminderToReferrer(email);
      await getReferralsFromApi();
      snackbar.enqueueSnackbar("Email reminder sent", { variant: "success" });
    } catch (error) {
      snackbar.enqueueSnackbar("Error sending reminder email", { variant: "error" });
    }
  };

  const sync = async (dealId: string) => {
    try {
      await syncDeal(dealId);
      snackbar.enqueueSnackbar("Sync queued", { variant: "success" });
    } catch (error) {
      snackbar.enqueueSnackbar("Error syncing", { variant: "error" });
    }
  };

  return (
    <div className="view-container">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="header">
            <Button
              onClick={() => {
                history.push("/referrals");
              }}
              variant="contained"
              size="small"
              className={"timecard-detail-bottombar-button"}>
              <ArrowBackIcon className={"timecard-detail-bottombar-icon"} />
              Back
            </Button>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography style={{ fontWeight: 500, fontSize: "1.5rem", textAlign: "center" }}>
                {referral?.referrer}
              </Typography>

              <Box style={{ width: "80%", marginTop: 10, marginBottom: 10 }}>
                <Divider />
              </Box>

              <Typography style={{ fontWeight: 600, color: "#7D7D7D", fontSize: "0.75rem" }}>
                {referral?.referrerId ? `id: ${referral.referrerId}` : ""}
              </Typography>
            </Box>
            <Box />
          </div>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              marginTop: "3.5rem",
              marginLeft: "7rem",
              marginRight: "7rem",
            }}>
            <Field label="Referee" text={`${referral?.traveler}`} />
            <Field label="Referee's Recruiter" text={`${referral?.recruiterTraveler}`} />
            <Field label="Job ID" text={`${referral?.dealsheetId}`} />
            <Field label="Facility" text={`${referral?.facility}`} />
            <Field
              label="Hours"
              text={referral?.hours ? (referral.hours as number).toFixed(2) : "0.00"}
            />
          </Box>

          <Box
            style={{
              marginTop: "2.5rem",
              marginBottom: "2.5rem",
              marginLeft: "2rem",
              marginRight: "2rem",
            }}>
            <Divider />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              marginLeft: "7rem",
              marginRight: "7rem",
            }}>
            <Field label="Status" text={`${referral?.status}`} />
            <Field
              label="Paid Date"
              text={
                referral?.paidDate
                  ? moment(new Date(referral.paidDate as string)).format("MM/DD/YYYY")
                  : "N/A"
              }
            />
            <Field
              label="Amount"
              text={referral?.amount ? `$${(referral.amount as number).toFixed(2)}` : "$0.00"}
            />
            <Field label="Paperwork Status" text={`${referral?.paperworkStatus}`} />
            <Field
              label="Payment Type"
              text={`${
                referral?.paymentType === ReferralsPaymentType.Ukg ? "UKG" : referral?.paymentType
              } `}
            />
          </Box>
          <Box
            style={{
              marginTop: "2.5rem",
              marginBottom: "2.5rem",
              marginLeft: "2rem",
              marginRight: "2rem",
            }}>
            <Divider />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              marginLeft: "7rem",
              marginRight: "7rem",
            }}>
            <Field
              label="Last Sync Date"
              text={
                referral?.lastSyncDate
                  ? moment(new Date(referral.lastSyncDate as Date)).format("MM/DD/YYYY hh:mm A")
                  : "N/A"
              }
            />
          </Box>

          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            gap={2}
            style={{
              marginTop: "5rem",
              marginLeft: "7rem",
              marginRight: "7rem",
            }}>
            <Button
              color="primary"
              style={{ textTransform: "capitalize", fontSize: 16 }}
              disabled={referral?.status !== ReferralStatus.InProgress}
              onClick={toggleMarkDialog}>
              {"Mark as 'To Be Paid'"}
            </Button>
            {referral &&
              showPaperworkStatus &&
              getPaperworkStatusAction(referral, toggleDocumentsDialogOn)}
            {referral &&
              canSendEmailReminderReferrerPaperwork &&
              referral?.paperworkStatus === ReferrerPaperworkStatus.PendingSignature && (
                <Button
                  color="primary"
                  style={{ textTransform: "capitalize", fontSize: 16 }}
                  onClick={() =>
                    toggleEmailReminderDialogOn(
                      referral.dealId,
                      referral.referrerPaperworkEmail ?? referral.referrerEmail
                    )
                  }>
                  Pending Documents
                </Button>
              )}
            {referral && referral?.status !== ReferralStatus.Paid && (
              <Button
                color="primary"
                style={{ textTransform: "capitalize", fontSize: 16 }}
                onClick={() => sync(referral.dealId)}>
                Sync
              </Button>
            )}
          </Box>

          <ReferralsDocumentsDialog
            title="Send Documents"
            explanation="This action will send W9 and ACH documents for the referrer to sign."
            open={isDocumentsDialogOpen.open}
            referrerEmail={isDocumentsDialogOpen.referrerEmail}
            onClose={toggleDocumentsDialogOff}
            onConfirm={onConfirmSend}
            checkboxLabel="Send to a different email address"
          />

          <ConfirmDialog
            dialogTitle={"Mark as 'To Be Paid'?"}
            content="This action will mark the referrer as 'To Be Paid' and sync BPP and Hubspot, regardless of the hours needed."
            isOpen={isMarkOpen}
            onConfirm={handleMarkAsPaid}
            onClose={toggleMarkDialog}
            onCancel={toggleMarkDialog}
          />

          <ReferralsDocumentsDialog
            title="Pending Documents"
            explanation="Do you want to send the referrer a reminder to sign the W9 and ACH documents?"
            open={isEmailReminderDialogOpen.open}
            referrerEmail={isEmailReminderDialogOpen.referrerEmail}
            onClose={toggleEmailReminderDialogOff}
            onConfirm={handleSendReminder}
            checkboxLabel="Send the request to sign to a different email address"
          />
        </>
      )}
    </div>
  );
};

export default ReferralDetail;
