import { UpdateUserInfo, UserRoleInformation, UsersTableItem } from "types/users";
import { msalApiFetch } from "../commons/authConfig";
import { UsersEmailsTableItem } from "components/manageUsers/components/UsersEmailsTableItem";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "getmvpusers";

export const getMvpUsersApi = async () => {
  const url = `${baseUrl}/${endpoint}`;
  return msalApiFetch(url).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};

export const getDTUsers = async (
  name = "",
  email = "",
  searchByPayPeriod = false
): Promise<UsersTableItem[]> => {
  const url = `${baseUrl}/users/digitalTimecard`;

  return msalApiFetch(url, {
    body: JSON.stringify({ name: name, email: email, searchByPayPeriod: searchByPayPeriod }),
    method: "POST",
  }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};

export const getBPPUsers = async (): Promise<UsersTableItem[]> => {
  const url = `${baseUrl}/users/billAndPayPortal`;
  return msalApiFetch(url, { method: "GET" }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};

export const getUserInfo = async (email: string): Promise<UserRoleInformation> => {
  const url = `${baseUrl}/users/${email}/info`;
  return msalApiFetch(url, { method: "GET" }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};

export const updateUserRole = async (request: UpdateUserInfo) => {
  const url = `${baseUrl}/users/billAndPayPortal/changeInfo`;

  return msalApiFetch(url, {
    method: "POST",
    body: JSON.stringify({ ...request }),
  });
};

export const resetDTUser = async (externalId: string) => {
  const url = `${baseUrl}/users/digitalTimecard/${externalId}/reset`;
  return await msalApiFetch(url, { method: "POST" });
};

export const getUsersEmails = async (name = "", email = ""): Promise<UsersEmailsTableItem[]> => {
  const url = `${baseUrl}/users/digitalTimecard-emails`;

  return msalApiFetch(url, {
    body: JSON.stringify({ name: name, email: email }),
    method: "POST",
  }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};

export const addCandidateEmail = async (candidateId: string, email: string) => {
  const url = `${baseUrl}/users/${candidateId}/email/${email}`;
  return msalApiFetch(url, {
    method: "POST",
    body: JSON.stringify({ candidateId, email }),
  }).then((results) => {
    if (!results.ok) throw results;
  });
};

export const restoreCandidateEmail = async (candidateId: string, email: string) => {
  const url = `${baseUrl}/users/${candidateId}/email/${email}/restore`;
  return msalApiFetch(url, {
    method: "PUT",
    body: JSON.stringify({ candidateId, email }),
  }).then((results) => {
    if (!results.ok) throw results;
  });
};

export const deleteCandidateEmail = async (candidateId: string, email: string) => {
  const url = `${baseUrl}/users/${candidateId}/email/${email}`;
  return msalApiFetch(url, {
    method: "DELETE",
    body: JSON.stringify({ candidateId, email }),
  }).then((results) => {
    if (!results.ok) throw results;
  });
};
