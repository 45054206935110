import * as actionTypes from "../actions/actionTypes";

export function dtUsersEmailsTableReducer(state: any = null, action: any) {
  switch (action.type) {
    case actionTypes.DTEMAIL_EMAIL_CHANGED:
      return { ...state, email: action.value };
    case actionTypes.DTEMAILS_NAME_CHANGED:
      return { ...state, name: action.value };
    default:
      return { ...state };
  }
}
