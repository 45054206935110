import { Box, Button, CircularProgress, Switch, FormControlLabel } from "@mui/material";
import { getReferrals } from "api/ReferralsApi";
import {
  ReferralStatus,
  ReferralsDownloadType,
  ReferralsPaymentType,
  ReferrerPaperworkStatus,
} from "commons/Enums";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REFERRALS_TABLE_CHANGED, TITLE_CHANGED } from "redux/actions/actionTypes";
import { ReferralTableItem } from "types/referrals";
import { downloadReferralsDataExport } from "components/shared/functions/DownloadDataExport";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import DropdownButton from "./components/ReferralDropdownButton";
import { usePermissions, useReferalsDocument } from "hooks";
import { convertBase64ToBlob, downloadBlob } from "commons/utils";
import AlertDialog from "components/shared/components/AlertDialog";
import { ReferralsFAQDialog } from "./components/ReferralsFAQDialog";
import { Link } from "react-router-dom";
import BPPTable, { Column, SaveType } from "components/shared/components/bppTable/BPPTable";
import LoggedUser from "commons/LoggedUser";
import ReferralsDocumentsDialog from "./components/ReferralsDocumentsDialog";

type ReferralsDataIds =
  | "traveler"
  | "referrer"
  | "recruiterTraveler"
  | "hours"
  | "status"
  | "paidDate"
  | "paymentType"
  | "paperworkStatus";

type ReferralsData = Omit<ReferralTableItem, "dealId"> & {
  id: string;
};

const isReferralOk = (referral: ReferralsData, type: ReferralsPaymentType) => {
  return (
    referral.paymentType.toUpperCase() !== type.toUpperCase() ||
    referral.status !== ReferralStatus.ToBePaid ||
    (referral.paymentType.toUpperCase() === ReferralsPaymentType.Ukg.toUpperCase() &&
      !referral.referrerId)
  );
};

const Referrals = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    canExportReferrals,
    canRequestReferrerPaperwork,
    canDownloadReferrerPaperwork,
    canSeeReferrerPaperwork,
    canSendEmailReminderReferrerPaperwork,
  } = usePermissions();
  const referralsFilters = useSelector((state: RootState) => state.referrals);

  const [referrals, setReferrals] = useState<ReferralsData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [reportErrors, setReportErrors] = useState<string[]>([]);

  const [selected, setSelected] = useState<ReferralsData[]>([]);
  const [disabledIndexes, setDisabledIndexes] = useState<string[]>([]);

  const [includeZero, setIncludeZero] = useState<boolean>(false);
  const [includeCanceled, setIncludeCanceled] = useState<boolean>(false);
  const [showOnlyErrors, setShowOnlyErrors] = useState<boolean>(false);

  const {
    toggleDocumentsDialogOn,
    isEmailReminderDialogOpen,
    toggleEmailReminderDialogOff,
    toggleEmailReminderDialogOn,
    handleEmailReminderToReferrer,
    isDocumentsDialogOpen,
    toggleDocumentsDialogOff,
    handleSendDocumentsToReferrer,
  } = useReferalsDocument();

  const getColumns = () => {
    let columns: Column<ReferralsDataIds, ReferralsData>[] = [
      { id: "traveler", label: "Referee", width: "17%" },
      {
        id: "referrer",
        label: "Referrer",
        width: "17%",
        format: (value: unknown, data) => {
          const isSuperAdmin = LoggedUser.isSuperAdmin();

          return isSuperAdmin ? (
            <Link
              to={{ pathname: "/referrals/" + data.id }}
              style={{
                color: "#3f51b5",
                padding: 0,
                fontWeight: 500,
                textTransform: "capitalize",
                textDecoration: "none",
              }}>
              {value}
            </Link>
          ) : (
            (value as string)
          );
        },
      },
      {
        id: "recruiterTraveler",
        label: "Referee's Recruiter",
        width: "17%",
      },
      {
        id: "hours",
        label: "Hours",
        format: (value: unknown) => (value ? (value as number).toFixed(2) : "0.00"),
      },
      {
        id: "status",
        label: "Status",
        width: "12%",
        lookup: {
          [ReferralStatus.InProgress]: ReferralStatus.InProgress,
          [ReferralStatus.ToBePaid]: ReferralStatus.ToBePaid,
          [ReferralStatus.Paid]: ReferralStatus.Paid,
          [ReferralStatus.Cancelled]: ReferralStatus.Cancelled,
        },
      },
      {
        id: "paidDate",
        label: "Paid Date",
        format: (value: unknown) =>
          value ? moment(new Date(value as string)).format("MM/DD/YYYY") : "",
      },
      {
        id: "paymentType",
        label: "Payment Type",
        width: "12%",
        format: (value: unknown) =>
          value === ReferralsPaymentType.Ukg ? "UKG" : (value as string),
      },
      {
        id: "paperworkStatus",
        label: "Referrer Paperwork",
        lookup: {
          [ReferrerPaperworkStatus.NotSent]: ReferrerPaperworkStatus.NotSent,
          [ReferrerPaperworkStatus.PendingSignature]: ReferrerPaperworkStatus.PendingSignature,
          [ReferrerPaperworkStatus.Signed]: ReferrerPaperworkStatus.Signed,
        },
        format: (value: unknown, data) => {
          if (data.paymentType !== ReferralsPaymentType.NetSuite) {
            return <></>;
          }

          if (
            data.paperworkStatus === ReferrerPaperworkStatus.NotSent &&
            canRequestReferrerPaperwork
          ) {
            return (
              <Button
                onClick={() => toggleDocumentsDialogOn(data.id, data.referrerEmail)}
                variant="text"
                className={"referrals-referrer-send-button"}>
                Send Documents
              </Button>
            );
          }

          if (
            data.paperworkStatus === ReferrerPaperworkStatus.PendingSignature &&
            canSendEmailReminderReferrerPaperwork
          ) {
            return (
              <Button
                onClick={() =>
                  toggleEmailReminderDialogOn(
                    data.id,
                    data.referrerPaperworkEmail ?? data.referrerEmail
                  )
                }
                variant="text"
                className={"referrals-referrer-send-button"}>
                Pending Documents
              </Button>
            );
          }

          if (
            data.paperworkStatus === ReferrerPaperworkStatus.Signed &&
            canDownloadReferrerPaperwork
          ) {
            return (
              <>
                {data.paperworkDownloadUrl && (
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    flexDirection={"column"}>
                    <Button
                      href={data.paperworkDownloadUrl}
                      download
                      target="_blank"
                      className={"referrals-referrer-send-button"}>
                      Download
                    </Button>
                    <Button
                      onClick={() => toggleDocumentsDialogOn(data.id, data.referrerEmail)}
                      variant="text"
                      className={"referrals-referrer-send-button"}>
                      Resend Documents
                    </Button>
                  </Box>
                )}
              </>
            );
          }

          return data.paperworkStatus;
        },
        customFilter(value, data) {
          const filters = value as string[];

          if (filters.length === 0) {
            return true;
          }

          return filters.map((x) => x.toLowerCase()).includes(data.paperworkStatus.toLowerCase());
        },
      },
    ];

    if (!canSeeReferrerPaperwork) {
      columns = columns.filter((x) => x.id !== "paperworkStatus");
    }

    return columns;
  };

  async function getReferralsFromApi(): Promise<void> {
    setLoading(true);
    const referrals = await getReferrals(includeZero, includeCanceled, showOnlyErrors);
    setReferrals(referrals.map((x) => ({ ...x, id: x.dealId })));
    setLoading(false);
  }

  function canExportUKG(referral: ReferralsData): boolean {
    return (
      referral.status === ReferralStatus.ToBePaid &&
      referral.paymentType === ReferralsPaymentType.Ukg
    );
  }

  function canExportNetSuite(referral: ReferralsData): boolean {
    return (
      referral.status === ReferralStatus.ToBePaid &&
      referral.paymentType === ReferralsPaymentType.NetSuite
    );
  }

  const openAlertDialog = (): void => {
    setIsAlertDialogOpen(true);
  };

  const closeAlertDialog = (): void => {
    setIsAlertDialogOpen(false);
  };

  const isSelectedUKG = selected.some(
    (item) => item.paymentType.toUpperCase() === ReferralsPaymentType.Ukg.toUpperCase()
  );

  async function handleDownload(
    downloadType: ReferralsDownloadType,
    isFinalDownload: boolean,
    type: ReferralsPaymentType
  ): Promise<void> {
    let referralsToDownload = selected;

    if (downloadType === ReferralsDownloadType.DownloadAll) {
      referralsToDownload = referrals.filter((referral) => {
        const canExport =
          type.toUpperCase() === ReferralsPaymentType.Ukg.toUpperCase()
            ? canExportUKG(referral)
            : canExportNetSuite(referral);
        return (
          referral.status === ReferralStatus.ToBePaid &&
          referral.paymentType === type &&
          canExport &&
          !!referral.referrerId
        );
      });
    }

    try {
      const response = await downloadReferralsDataExport(
        referralsToDownload.map((x) => ({ ...x, dealId: x.id })) as ReferralTableItem[],
        isFinalDownload,
        type.toUpperCase() === ReferralsPaymentType.Ukg.toUpperCase()
      );

      if (response.file) {
        setReportErrors([]);
        const blob = convertBase64ToBlob(response.file);

        await downloadBlob(blob, "report.csv");
        if (isFinalDownload) {
          getReferralsFromApi().catch((e) => {
            console.error(e);
          });
        }
      } else if (response.validationErrors?.length > 0) {
        setReportErrors(response.validationErrors);
        openAlertDialog();
      }
    } catch (error) {
      alert(error);
    }
  }

  const isDownloadAllNetSuiteDisabled =
    referrals.filter(
      (referral) =>
        referral.paymentType === ReferralsPaymentType.NetSuite &&
        canExportNetSuite(referral) &&
        !!referral.referrerId
    ).length <= 0;

  const isDownloadAllUkgDisabled =
    referrals.filter(
      (referral) =>
        referral.paymentType === ReferralsPaymentType.Ukg &&
        canExportUKG(referral) &&
        !!referral.referrerId
    ).length <= 0;
  const isDownloadSelectedDisabled = selected.length <= 0;

  const netSuiteDownloadOptions = [
    {
      name: "Download All",
      disabled: isDownloadAllNetSuiteDisabled,
      onDownload: handleDownload,
    },
    {
      name: `Download Selected ${selected.length > 0 ? "(" + selected.length + ")" : ""}`,
      disabled: !isDownloadAllNetSuiteDisabled && isDownloadSelectedDisabled,
      onDownload: handleDownload,
    },
  ];

  const ukgDownloadOptions = [
    {
      name: "Download All",
      disabled: isDownloadAllUkgDisabled,
      onDownload: handleDownload,
    },
    {
      name: `Download Selected ${selected.length > 0 ? "(" + selected.length + ")" : ""}`,
      disabled: isDownloadSelectedDisabled,
      onDownload: handleDownload,
    },
  ];

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Referrals" });
    handleSearch();
  }, []);

  const handleSelectAllClick = () => {
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.findIndex((x) => x.id === id);
    let newSelected: ReferralsData[] = [];

    if (selectedIndex === -1) {
      const referral = referrals.find((x) => x.id === id);
      newSelected = newSelected.concat(selected, referral!);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    if (selected.some((item) => item.paymentType === ReferralsPaymentType.NetSuite)) {
      setDisabledIndexes(
        referrals
          .filter((referral) => isReferralOk(referral, ReferralsPaymentType.NetSuite))
          .map((x) => x.id)
      );
    } else if (selected.some((item) => item.paymentType === ReferralsPaymentType.Ukg)) {
      setDisabledIndexes(
        referrals
          .filter((referral) => isReferralOk(referral, ReferralsPaymentType.Ukg))
          .map((x) => x.id)
      );
    } else {
      setDisabledIndexes(
        referrals
          .filter(
            (referral) =>
              referral.status !== ReferralStatus.ToBePaid ||
              (referral.paymentType.toUpperCase() === ReferralsPaymentType.Ukg.toUpperCase() &&
                !referral.referrerId)
          )
          .map((x) => x.id)
      );
    }
  }, [selected, referrals]);

  const handleChange = (newValue: SaveType<ReferralsDataIds>): void => {
    dispatch({ type: REFERRALS_TABLE_CHANGED, referrals: newValue });
  };

  const handleSearch = async () => {
    getReferralsFromApi().catch((e) => {
      console.error(e);
    });
  };

  function handleIncludeZeroToggleChange(event: ChangeEvent<HTMLInputElement>) {
    setIncludeZero(event.target.checked);
  }

  function handleIncludeCanceledToggleChange(event: ChangeEvent<HTMLInputElement>) {
    setIncludeCanceled(event.target.checked);
  }

  function handleShowOnlyErrorsToggleChange(event: ChangeEvent<HTMLInputElement>) {
    setShowOnlyErrors(event.target.checked);
  }

  return (
    <div className="view-container">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box mb={2} mt={1} display="flex">
            <CanRenderChildren permissionName="canExportReferrals" featureFlagName="isReferralsOn">
              {ukgDownloadOptions.length > 0 && (
                <DropdownButton
                  options={ukgDownloadOptions}
                  buttonText="UKG Export"
                  disabled={
                    (selected.length > 0 && !isSelectedUKG) ||
                    (selected.length === 0 && isDownloadAllUkgDisabled)
                  }
                  paymentType={ReferralsPaymentType.Ukg}
                />
              )}
              {netSuiteDownloadOptions.length > 0 && (
                <DropdownButton
                  options={netSuiteDownloadOptions}
                  buttonText="NetSuite Export"
                  disabled={isSelectedUKG}
                  paymentType={ReferralsPaymentType.NetSuite}
                />
              )}
            </CanRenderChildren>
            <Button
              href={"https://share.hsforms.com/1gCVmKhrGQPW0X8ppuXA0qw2bnk7"}
              color="primary"
              target="_blank">
              Referral Bonus Submission Form
            </Button>
            <Box display="flex" flexGrow={1} justifyContent={"end"}>
              <ReferralsFAQDialog />
            </Box>
          </Box>
          <BPPTable<ReferralsData, ReferralsDataIds>
            columns={getColumns()}
            data={referrals}
            handleClearSelected={handleSelectAllClick}
            handleSelectedClick={handleClick}
            selected={canExportReferrals ? selected : undefined}
            disabledIds={disabledIndexes}
            handleSave={handleChange}
            savedData={referralsFilters}
            customSearch={
              <Box ml={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={includeZero}
                      onChange={handleIncludeZeroToggleChange}
                      name="IncludeZero"
                      color="primary"
                    />
                  }
                  label="Include Zero Hours"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={includeCanceled}
                      onChange={handleIncludeCanceledToggleChange}
                      name="IncludeCanceled"
                      color="primary"
                    />
                  }
                  label="Include Cancelled"
                />

                <CanRenderChildren permissionName="canSeeShowErrorToggleReferrals">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showOnlyErrors}
                        onChange={handleShowOnlyErrorsToggleChange}
                        name="ShowOnlyErrors"
                        color="primary"
                      />
                    }
                    label="Show only errors"
                  />
                </CanRenderChildren>
                <Button
                  onClick={handleSearch}
                  variant="contained"
                  color="primary"
                  startIcon={<SearchIcon />}>
                  Search
                </Button>
              </Box>
            }
          />

          <AlertDialog
            isOpen={isAlertDialogOpen}
            onClose={closeAlertDialog}
            dialogText={reportErrors.map((e) => (
              <p key={e}>{e}</p>
            ))}
            dialogTitle="Warning"
          />

          <ReferralsDocumentsDialog
            title="Send Documents?"
            explanation="This action will send W9 and ACH documents for the referrer to sign."
            open={isDocumentsDialogOpen.open}
            referrerEmail={isDocumentsDialogOpen.referrerEmail}
            onClose={toggleDocumentsDialogOff}
            onConfirm={handleSendDocumentsToReferrer}
            checkboxLabel="Send to a different email address"
          />

          <ReferralsDocumentsDialog
            title="Pending Documents"
            explanation="Do you want to send the referrer a reminder to sign the W9 and ACH documents?"
            open={isEmailReminderDialogOpen.open}
            referrerEmail={isEmailReminderDialogOpen.referrerEmail}
            onClose={toggleEmailReminderDialogOff}
            onConfirm={handleEmailReminderToReferrer}
            checkboxLabel="Send the request to sign to a different email address"
          />
        </>
      )}
    </div>
  );
};

export default Referrals;
