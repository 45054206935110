import React, { useState } from "react";
import Button from "@mui/material/Button";
import AssignmentReturn from "@mui/icons-material/AssignmentReturn";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ApproveIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import LoggedUser from "commons/LoggedUser";
import features from "commons/Features";
import { usePermissions } from "hooks";
import { TimecardUploadSource, TimesheetStatus } from "commons/Enums";
import ConfirmDialog from "components/shared/components/ConfirmDialog";

type TimecardDetailBottombarProps = {
  assignment: any;
  onNoteSave: any;
  onBilling: any;
  onApprove: any;
  onSave: any;
  onReturn: any;
  onSaveApproved: any;
  featureFlags: any;
  onReject: any;
  onDeleteTimecard: any;
};

const TimecardDetailBottombar = ({
  assignment,
  onNoteSave,
  onBilling,
  onApprove,
  onSave,
  onSaveApproved,
  onReturn,
  featureFlags,
  onReject,
  onDeleteTimecard,
}: TimecardDetailBottombarProps): JSX.Element => {
  const { canSeeReturnToPending, canSeeTimecardBilling, canSeeTimecardApprove, canRejectTimecard } =
    usePermissions();
  const [isDeleteTimecardDialogOpen, setDeleteTimecardDialogOpen] = useState(false);
  const isRecruiterTeamLead = LoggedUser.isRecruiterTeamLead();
  const isAssistant = LoggedUser.isAssistant();
  const isRecruiter = LoggedUser.isRecruiter();
  const isSuperAdmin = LoggedUser.isSuperAdmin();
  const isSalesRep = LoggedUser.isSalesRep();
  const timesheetLocked = assignment.locked;
  const Features = features;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const showReturnToPending =
    (assignment.status === TimesheetStatus.FOR_REVIEW ||
      (assignment.status === TimesheetStatus.APPROVED &&
        canSeeReturnToPending &&
        !timesheetLocked) ||
      assignment.status === TimesheetStatus.BILLING) &&
    !isSalesRep &&
    !assignment.isAdjustment &&
    !assignment.isExpenseOnly &&
    !assignment.hasEditRequest;

  const showReject =
    canRejectTimecard &&
    (assignment.status === TimesheetStatus.DRAFT ||
      assignment.status === TimesheetStatus.RETURNED) &&
    !assignment.hasToAcknowledgeNotes &&
    !assignment.hasEditRequest &&
    assignment.timecardUploadSource === TimecardUploadSource.DigitalTimecards;

  const showAdditionalNote = assignment.status === TimesheetStatus.FOR_REVIEW;

  const showBilling =
    Features.BillingIsOn(featureFlags) &&
    assignment.status === TimesheetStatus.FOR_REVIEW &&
    !assignment.hasEditRequest &&
    canSeeTimecardBilling;

  const showApprove =
    !Features.BillingIsOn(featureFlags) &&
    assignment.status === TimesheetStatus.FOR_REVIEW &&
    canSeeTimecardApprove;

  const showSaveAndApprove =
    Features.BillingIsOn(featureFlags) &&
    assignment.status === TimesheetStatus.BILLING &&
    !assignment.hasEditRequest &&
    !isSalesRep;

  const showSaveAndSubmit =
    !(isRecruiterTeamLead || isRecruiter || isSalesRep || isAssistant) &&
    (assignment.timecardUploadId ||
      assignment.hasExpenses ||
      (assignment.isAdjustment && isSuperAdmin)) &&
    (assignment.status === TimesheetStatus.DRAFT ||
      assignment.status === TimesheetStatus.RETURNED ||
      assignment.status === TimesheetStatus.REJECTED) &&
    !assignment.hasEditRequest &&
    !assignment.hasToAcknowledgeNotes;

  const showSaveSuperAdminApprove =
    isSuperAdmin && assignment.status === TimesheetStatus.APPROVED && !assignment.isExpenseOnly;

  const showDeleteTimecardForAdjustment =
    assignment.status === TimesheetStatus.DRAFT && assignment.isAdjustment;

  const toggleOnRejectDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <>
      <ConfirmDialog
        dialogTitle={"Reject Timecard"}
        content="Do you want to reject this timecard?"
        isOpen={isDialogOpen}
        onConfirm={onReject}
        onClose={toggleOnRejectDialog}
        onCancel={toggleOnRejectDialog}
      />
      {showDeleteTimecardForAdjustment && (
        <ConfirmDialog
          isOpen={isDeleteTimecardDialogOpen}
          onClose={() => setDeleteTimecardDialogOpen(false)}
          onConfirm={onDeleteTimecard}
          content="Are you sure you want to delete this adjustments timecard??"
          dialogTitle="Delete Timecard"
        />
      )}

      <div className="timecard-detail-bottombar">
        {showSaveSuperAdminApprove && (
          <Button
            disabled={
              !(
                assignment.dirtyTimecard ||
                assignment.dirtyTimecardAdjustments ||
                assignment.dirtyExpenses ||
                assignment.dirtyBillingEntries ||
                assignment.dirtyCallOffs
              ) || assignment.saving
            }
            onClick={() => onSaveApproved()}
            variant="contained"
            size="small"
            className={"timecard-detail-bottombar-save-button"}>
            <SaveIcon className={"timecard-detail-bottombar-icon"} />
            Save
          </Button>
        )}
        {showReturnToPending && (
          <div>
            <Button
              disabled={assignment.saving || assignment.savingNote || false}
              onClick={() => onReturn()}
              variant="contained"
              size="small"
              className={"timecard-detail-bottombar-button"}>
              <AssignmentReturn className={"timecard-detail-bottombar-icon"} />
              Return to Pending
            </Button>
          </div>
        )}
        {showAdditionalNote && (
          <Button
            disabled={!assignment.dirtyNote || assignment.saving || assignment.savingNote}
            onClick={() => onNoteSave()}
            variant="contained"
            size="small"
            className={"timecard-detail-bottombar-save-additional-note-button"}>
            <SaveIcon className={"timecard-detail-bottombar-icon"} />
            Save Additional Note
          </Button>
        )}
        {showBilling && (
          <Button
            disabled={assignment.saving || assignment.savingNote || false}
            onClick={() => onBilling()}
            variant="contained"
            size="small"
            className={"timecard-detail-bottombar-button"}>
            <ApproveIcon className={"timecard-detail-bottombar-icon"} />
            Move to Billing
          </Button>
        )}
        {showApprove && (
          <Button
            disabled={assignment.saving || assignment.savingNote || false}
            onClick={() => onApprove()}
            variant="contained"
            size="small"
            className={"timecard-detail-bottombar-approve-button"}>
            <ApproveIcon className={"timecard-detail-bottombar-icon"} />
            Approve
          </Button>
        )}
        {showSaveAndSubmit && (
          <>
            <div>
              <Button
                disabled={
                  !(
                    assignment.dirtyNote ||
                    assignment.dirtyTimecard ||
                    assignment.dirtyTimecardAdjustments ||
                    assignment.dirtyExpenses ||
                    assignment.dirtyCallOffs
                  ) || assignment.saving
                }
                onClick={() => onSave(false)}
                variant="contained"
                size="small"
                className={"timecard-detail-bottombar-button"}>
                <SaveIcon className={"timecard-detail-bottombar-icon"} />
                Save
              </Button>
              {showReject && (
                <Button
                  disabled={assignment.saving || assignment.savingNote || false}
                  onClick={toggleOnRejectDialog}
                  variant="contained"
                  size="small"
                  className={"timecard-detail-bottombar-button"}>
                  <DeleteForeverIcon className={"timecard-detail-bottombar-icon"} />
                  Reject
                </Button>
              )}
            </div>

            {showDeleteTimecardForAdjustment && (
              <>
                <Button
                  disabled={assignment.saving || assignment.savingNote || false}
                  onClick={() => setDeleteTimecardDialogOpen(true)}
                  variant="contained"
                  size="small"
                  className={"timecard-detail-bottombar-button"}>
                  <DeleteForeverIcon className={"timecard-detail-bottombar-icon"} />
                  Delete
                </Button>
              </>
            )}

            <Button
              disabled={assignment.saving}
              onClick={() => onSave(true)}
              variant="contained"
              size="small"
              className={"timecard-detail-bottombar-button"}>
              {assignment.isAdjustment ? (
                <ApproveIcon className={"timecard-detail-bottombar-icon"} />
              ) : (
                <SendIcon className={"timecard-detail-bottombar-icon"} />
              )}
              {assignment.isAdjustment ? "Approve" : "Submit"}
            </Button>
          </>
        )}
        {showSaveAndApprove && (
          <>
            <div>
              <Button
                disabled={
                  !(
                    assignment.dirtyNote ||
                    assignment.dirtyTimecard ||
                    assignment.dirtyTimecardAdjustments ||
                    assignment.dirtyExpenses ||
                    assignment.dirtyCallOffs
                  ) || assignment.saving
                }
                onClick={() => onSave(false)}
                variant="contained"
                size="small"
                className={"timecard-detail-bottombar-button"}>
                <SaveIcon className={"timecard-detail-bottombar-icon"} />
                Save
              </Button>

              <Button
                disabled={assignment.saving || assignment.savingNote || false}
                onClick={() => onApprove()}
                variant="contained"
                size="small"
                className={"timecard-detail-bottombar-approve-button"}>
                <ApproveIcon className={"timecard-detail-bottombar-icon"} />
                Approve
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TimecardDetailBottombar;
