import React, { useState } from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
import TimesheetApi from "../../../api/TimesheetApi";
import PayrollApi from "../../../api/PayrollApi";
import { ErrorMessages } from "../../../commons/Enums";
import ExpenseJobAssignmentSelector from "components/expenses/ExpenseJobAssignmentSelector";

type NewTimecardDialogProps = {
  isAdjustment: boolean;
  currentRecruiterId: string;
  onClose: (_: boolean, id?: string) => void;
  open: boolean;
};

const NewTimecardDialog = (props: NewTimecardDialogProps) => {

  const [saving, setSaving] = useState<boolean>(false);

  const snackbar = useSnackbar();

  const onAssignmentSelected = async (assignment: AssignmentSelected) => {    
    validateAndCreateTimecard(assignment.dealsheetId);
  };

  const createNewTimecard = (selectedAssignmentId: string) => {
    const curr = new Date();
    const startDate = moment(new Date(curr.setDate(curr.getDate() - curr.getDay()))).subtract(
      7,
      "days"
    );
    const endDate = moment(new Date(curr.setDate(curr.getDate() - curr.getDay() + 6))).subtract(
      7,
      "days"
    );

    if (props.isAdjustment === true) {
      TimesheetApi.createNewAdjustmentTimecard(selectedAssignmentId)
        .then((result) => {
          props.onClose(true, result.timeSheetId.toUpperCase());
        })
        .catch(() => {
          setSaving(false);
          snackbar.enqueueSnackbar("An error occurred adding the new traveler.");
        });
    } else {
      TimesheetApi.createNewTimecard(selectedAssignmentId, startDate, endDate)
        .then((result) => {
          props.onClose(true, result.timeSheetId.toUpperCase());
        })
        .catch(() => {
          setSaving(false);
          snackbar.enqueueSnackbar("An error occurred adding the new traveler.");
        });
    }
  };

  const validateAndCreateTimecard = (selectedAssignmentId: string) => {
    setSaving(true);    
    if (props.isAdjustment === false && selectedAssignmentId) {
      PayrollApi.validateLockRecruiterPayroll(selectedAssignmentId).then(
        (isLocked) => {
          if (isLocked) {
            snackbar.enqueueSnackbar(ErrorMessages.LockedPayrollMessage);
            setSaving(false);
            return;
          }
          createNewTimecard(selectedAssignmentId);
        }
      );
    } else {
      TimesheetApi.valdiateNewAdjustmentTimecard(selectedAssignmentId)
        .then((isValid) => {
          if (!isValid) {
            snackbar.enqueueSnackbar(ErrorMessages.DuplicatedOrInvalidTimecarAdjustment);
            setSaving(false);
            return;
          }
          createNewTimecard(selectedAssignmentId);
        })
        .catch(() => {
          snackbar.enqueueSnackbar(ErrorMessages.DuplicatedOrInvalidTimecarAdjustment);
          setSaving(false);
        });
    }
  };

  const close = () => {
    props.onClose(false);
  };

  return (
    <ExpenseJobAssignmentSelector
        recruiterId={props.currentRecruiterId}
        open={props.open}
        onClose={close}
        onSelect={onAssignmentSelected}
      />    
  );
};

export default NewTimecardDialog;
