import { TextField } from "@mui/material";
import { addCandidateEmail } from "api/UsersApi";
import ConfirmDialog from "components/shared/components/ConfirmDialog";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

type AddUserEmailDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSearch: () => void;
  selectedCandidateId: string;
};

const AddUserEmailDialog = ({
  isOpen,
  onClose,
  onSearch,
  selectedCandidateId,
}: AddUserEmailDialogProps) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSave = async () => {
    const regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    const isEmailValid = regex.test(email);

    if (!isEmailValid) {
      enqueueSnackbar("Invalid email", { variant: "error" });
      return;
    }

    setLoading(true);

    await addCandidateEmail(selectedCandidateId, email);

    setLoading(false);
    onClose();
    onSearch();
  };

  useEffect(() => {
    if (!isOpen) setEmail("");
  }, [isOpen]);

  return (
    <ConfirmDialog
      centerTitle
      dividerEnabled
      isOpen={isOpen}
      maxWidth={"xl"}
      content={
        <TextField variant="outlined" label="Email" onChange={handleEmailChange} value={email} />
      }
      dialogTitle="Add Email"
      cancelText="Close"
      confirmText="Save"
      onCancel={onClose}
      onConfirm={handleSave}
      isLoading={loading}
    />
  );
};

export default AddUserEmailDialog;
