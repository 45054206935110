import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useAppDispatch } from "hooks";
import { TITLE_CHANGED } from "redux/actions/actionTypes";
import { DTUsers } from "./components/DTUsers";
import { BPPUsers } from "./components/BPPUsers";
import UsersEmails from "./components/UsersEmails";

enum CurrentTab {
  DT_USERS = "1",
  BPP_USERS = "2",
  USERS_EMAILS = "3",
}

const ManageUsers = () => {
  const [currentTab, setCurrentTab] = useState(CurrentTab.DT_USERS);
  const dispatch = useAppDispatch();

  const handleChange = (_: React.SyntheticEvent, newValue: CurrentTab) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Manage Users" });
  }, []);

  return (
    <Box mx={3}>
      <Box sx={{ display: "flex", width: "100%", marginTop: 2, marginBottom: 2 }}>
        <Tabs value={currentTab} onChange={handleChange} centered sx={{ width: "100%" }}>
          <Tab
            label="Digital Timecard Users"
            id="dt-users"
            value={CurrentTab.DT_USERS}
            sx={{ fontSize: 14 }}
          />
          <Tab
            label="Digital Timecard emails"
            id="users-emails"
            value={CurrentTab.USERS_EMAILS}
            sx={{ fontSize: 14 }}
          />
          <Tab
            label="Bill and Pay Portal Users"
            id="bpp-users"
            value={CurrentTab.BPP_USERS}
            sx={{ fontSize: 14 }}
          />
        </Tabs>
      </Box>
      <Box sx={{ display: currentTab === CurrentTab.DT_USERS ? "block" : "none" }}>
        <DTUsers />
      </Box>
      <Box sx={{ display: currentTab === CurrentTab.BPP_USERS ? "block" : "none" }}>
        <BPPUsers />
      </Box>
      <Box sx={{ display: currentTab === CurrentTab.USERS_EMAILS ? "block" : "none" }}>
        <UsersEmails />
      </Box>
    </Box>
  );
};

export default ManageUsers;
